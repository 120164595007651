"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Klaas Custom Woodworking LTD.
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenTypeToJSON = exports.TokenTypeFromJSONTyped = exports.TokenTypeFromJSON = exports.TokenType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var TokenType;
(function (TokenType) {
    TokenType["Admin"] = "Admin";
    TokenType["User"] = "User";
})(TokenType = exports.TokenType || (exports.TokenType = {}));
function TokenTypeFromJSON(json) {
    return TokenTypeFromJSONTyped(json, false);
}
exports.TokenTypeFromJSON = TokenTypeFromJSON;
function TokenTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TokenTypeFromJSONTyped = TokenTypeFromJSONTyped;
function TokenTypeToJSON(value) {
    return value;
}
exports.TokenTypeToJSON = TokenTypeToJSON;
