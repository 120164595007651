"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Klaas Custom Woodworking LTD.
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpiceRackMaterialToJSON = exports.SpiceRackMaterialFromJSONTyped = exports.SpiceRackMaterialFromJSON = exports.SpiceRackMaterial = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var SpiceRackMaterial;
(function (SpiceRackMaterial) {
    SpiceRackMaterial["MAPLE"] = "MAPLE";
    SpiceRackMaterial["BALTIC"] = "BALTIC";
})(SpiceRackMaterial = exports.SpiceRackMaterial || (exports.SpiceRackMaterial = {}));
function SpiceRackMaterialFromJSON(json) {
    return SpiceRackMaterialFromJSONTyped(json, false);
}
exports.SpiceRackMaterialFromJSON = SpiceRackMaterialFromJSON;
function SpiceRackMaterialFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.SpiceRackMaterialFromJSONTyped = SpiceRackMaterialFromJSONTyped;
function SpiceRackMaterialToJSON(value) {
    return value;
}
exports.SpiceRackMaterialToJSON = SpiceRackMaterialToJSON;
