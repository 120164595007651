import {chain, fraction, Fraction} from "mathjs";
import {isNil} from "lodash";

function convertMeasurementStringToNumber(measurement: string): number {
    let wholePart, fractionalPart;
    if (measurement.split(" ").length === 1) {
        if (measurement.split("/").length === 1) {
            return Number(measurement);
        } else {
            wholePart = 0;
            fractionalPart = measurement
        }
    } else {
        wholePart = Number(measurement.split(" ")[0]);
        fractionalPart = measurement.split(" ")[1];
    }

    const fraction = fractionalPart ? chain(Number(fractionalPart.split("/")[0])).divide(Number(fractionalPart.split("/")[1])).done() : 0;
    return chain(wholePart).add(fraction).done();
}

function measurementSubtract(measurement: string, subtract: string): string {
    if (isNil(measurement)) {
        return undefined;
    }

    const numberMeasurement = convertMeasurementStringToNumber(measurement);
    if (isNil(numberMeasurement) || isNaN(numberMeasurement) || numberMeasurement === Infinity) {
        return undefined;
    }

    const numberSubtract = convertMeasurementStringToNumber(subtract);

    if (numberMeasurement < numberSubtract) {
        return "ERROR";
    }

    if (numberSubtract === 0) {
        return measurement;
    }

    const numberResult = chain(numberMeasurement).subtract(numberSubtract).done();
    const mathFraction: Fraction = fraction(numberResult) as Fraction;

    const whole = chain(mathFraction.n).divide(mathFraction.d).floor().done();
    const numerator = mathFraction.n % mathFraction.d;
    const denominator = mathFraction.d;

    let result
    if (whole === 0) {
        if (numerator === 0) {
            result = "0";
        } else {
            result = `${numerator}/${denominator}`;
        }
    } else {
        if (numerator === 0) {
            result = `${whole}`;
        } else {
            result = `${whole} ${numerator}/${denominator}`;
        }
    }

    return result;
}

export {convertMeasurementStringToNumber, measurementSubtract};