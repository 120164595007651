"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Klaas Custom Woodworking LTD.
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhoneNumberVerificationToJSON = exports.PhoneNumberVerificationFromJSONTyped = exports.PhoneNumberVerificationFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PhoneNumberVerificationFromJSON(json) {
    return PhoneNumberVerificationFromJSONTyped(json, false);
}
exports.PhoneNumberVerificationFromJSON = PhoneNumberVerificationFromJSON;
function PhoneNumberVerificationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'phoneNumberVerificationType': _1.PhoneNumberVerificationTypeFromJSON(json['phoneNumberVerificationType']),
        'user': !runtime_1.exists(json, 'user') ? undefined : _1.UserFromJSON(json['user']),
        'phoneNumber': json['phoneNumber'],
        'verificationCodeHash': json['verificationCodeHash'],
        'messageSID': json['messageSID'],
        'creationDate': json['creationDate'],
    };
}
exports.PhoneNumberVerificationFromJSONTyped = PhoneNumberVerificationFromJSONTyped;
function PhoneNumberVerificationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'phoneNumberVerificationType': _1.PhoneNumberVerificationTypeToJSON(value.phoneNumberVerificationType),
        'user': _1.UserToJSON(value.user),
        'phoneNumber': value.phoneNumber,
        'verificationCodeHash': value.verificationCodeHash,
        'messageSID': value.messageSID,
        'creationDate': value.creationDate,
    };
}
exports.PhoneNumberVerificationToJSON = PhoneNumberVerificationToJSON;
