"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Klaas Custom Woodworking LTD.
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.WallCutlistSortByToJSON = exports.WallCutlistSortByFromJSONTyped = exports.WallCutlistSortByFromJSON = exports.WallCutlistSortBy = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var WallCutlistSortBy;
(function (WallCutlistSortBy) {
    WallCutlistSortBy["HARDWOOD"] = "HARDWOOD";
    WallCutlistSortBy["BALTICBIRCH"] = "BALTIC_BIRCH";
})(WallCutlistSortBy = exports.WallCutlistSortBy || (exports.WallCutlistSortBy = {}));
function WallCutlistSortByFromJSON(json) {
    return WallCutlistSortByFromJSONTyped(json, false);
}
exports.WallCutlistSortByFromJSON = WallCutlistSortByFromJSON;
function WallCutlistSortByFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.WallCutlistSortByFromJSONTyped = WallCutlistSortByFromJSONTyped;
function WallCutlistSortByToJSON(value) {
    return value;
}
exports.WallCutlistSortByToJSON = WallCutlistSortByToJSON;
