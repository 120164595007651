"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Klaas Custom Woodworking LTD.
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserNoteToJSON = exports.UserNoteFromJSONTyped = exports.UserNoteFromJSON = void 0;
const _1 = require("./");
function UserNoteFromJSON(json) {
    return UserNoteFromJSONTyped(json, false);
}
exports.UserNoteFromJSON = UserNoteFromJSON;
function UserNoteFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'creator': _1.AdminFromJSON(json['creator']),
        'user': _1.UserFromJSON(json['user']),
        'text': json['text'],
        'creationDate': json['creationDate'],
        'lastTouched': json['lastTouched'],
    };
}
exports.UserNoteFromJSONTyped = UserNoteFromJSONTyped;
function UserNoteToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'creator': _1.AdminToJSON(value.creator),
        'user': _1.UserToJSON(value.user),
        'text': value.text,
        'creationDate': value.creationDate,
        'lastTouched': value.lastTouched,
    };
}
exports.UserNoteToJSON = UserNoteToJSON;
