"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Klaas Custom Woodworking LTD.
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminToJSON = exports.AdminFromJSONTyped = exports.AdminFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function AdminFromJSON(json) {
    return AdminFromJSONTyped(json, false);
}
exports.AdminFromJSON = AdminFromJSON;
function AdminFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'adminRole': _1.AdminRoleFromJSON(json['adminRole']),
        'active': json['active'],
        'username': json['username'],
        'passwordHash': !runtime_1.exists(json, 'passwordHash') ? undefined : json['passwordHash'],
        'firstName': !runtime_1.exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !runtime_1.exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
    };
}
exports.AdminFromJSONTyped = AdminFromJSONTyped;
function AdminToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'adminRole': _1.AdminRoleToJSON(value.adminRole),
        'active': value.active,
        'username': value.username,
        'passwordHash': value.passwordHash,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
    };
}
exports.AdminToJSON = AdminToJSON;
