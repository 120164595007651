"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Klaas Custom Woodworking LTD.
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenToJSON = exports.TokenFromJSONTyped = exports.TokenFromJSON = void 0;
const _1 = require("./");
function TokenFromJSON(json) {
    return TokenFromJSONTyped(json, false);
}
exports.TokenFromJSON = TokenFromJSON;
function TokenFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'tokenType': _1.TokenTypeFromJSON(json['tokenType']),
        'owner': json['owner'],
        'token': json['token'],
        'timesExtended': json['timesExtended'],
        'lastTouched': json['lastTouched'],
    };
}
exports.TokenFromJSONTyped = TokenFromJSONTyped;
function TokenToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'tokenType': _1.TokenTypeToJSON(value.tokenType),
        'owner': value.owner,
        'token': value.token,
        'timesExtended': value.timesExtended,
        'lastTouched': value.lastTouched,
    };
}
exports.TokenToJSON = TokenToJSON;
