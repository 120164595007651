"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Klaas Custom Woodworking LTD.
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpicePartListBodyToJSON = exports.SpicePartListBodyFromJSONTyped = exports.SpicePartListBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function SpicePartListBodyFromJSON(json) {
    return SpicePartListBodyFromJSONTyped(json, false);
}
exports.SpicePartListBodyFromJSON = SpicePartListBodyFromJSON;
function SpicePartListBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'material': _1.SpiceRackMaterialFromJSON(json['material']),
        'customItem': !runtime_1.exists(json, 'customItem') ? undefined : _1.CustomSpiceRackItemFromJSON(json['customItem']),
        'quantities': _1.SpiceRackQuantitiesFromJSON(json['quantities']),
    };
}
exports.SpicePartListBodyFromJSONTyped = SpicePartListBodyFromJSONTyped;
function SpicePartListBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'material': _1.SpiceRackMaterialToJSON(value.material),
        'customItem': _1.CustomSpiceRackItemToJSON(value.customItem),
        'quantities': _1.SpiceRackQuantitiesToJSON(value.quantities),
    };
}
exports.SpicePartListBodyToJSON = SpicePartListBodyToJSON;
