"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Klaas Custom Woodworking LTD.
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForgotPasswordToJSON = exports.ForgotPasswordFromJSONTyped = exports.ForgotPasswordFromJSON = void 0;
const _1 = require("./");
function ForgotPasswordFromJSON(json) {
    return ForgotPasswordFromJSONTyped(json, false);
}
exports.ForgotPasswordFromJSON = ForgotPasswordFromJSON;
function ForgotPasswordFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'user': _1.UserFromJSON(json['user']),
        'verificationCodeHash': json['verificationCodeHash'],
        'creationDate': json['creationDate'],
    };
}
exports.ForgotPasswordFromJSONTyped = ForgotPasswordFromJSONTyped;
function ForgotPasswordToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'user': _1.UserToJSON(value.user),
        'verificationCodeHash': value.verificationCodeHash,
        'creationDate': value.creationDate,
    };
}
exports.ForgotPasswordToJSON = ForgotPasswordToJSON;
