"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Klaas Custom Woodworking LTD.
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DovetailMaterialsBodyToJSON = exports.DovetailMaterialsBodyFromJSONTyped = exports.DovetailMaterialsBodyFromJSON = void 0;
const _1 = require("./");
function DovetailMaterialsBodyFromJSON(json) {
    return DovetailMaterialsBodyFromJSONTyped(json, false);
}
exports.DovetailMaterialsBodyFromJSON = DovetailMaterialsBodyFromJSON;
function DovetailMaterialsBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'customerName': json['customerName'],
        'po': json['po'],
        'material': json['material'],
        'notchFront': json['notchFront'],
        'notchBack': json['notchBack'],
        'notes': json['notes'],
        'finished': json['finished'],
        'drilling': json['drilling'],
        'garbagePullouts': json['garbagePullouts'],
        'reduceSides': json['reduceSides'],
        'wallCutlistSortBy': _1.WallCutlistSortByFromJSON(json['wallCutlistSortBy']),
        'dovetailList': (json['dovetailList'].map(_1.DovetailFromJSON)),
    };
}
exports.DovetailMaterialsBodyFromJSONTyped = DovetailMaterialsBodyFromJSONTyped;
function DovetailMaterialsBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'customerName': value.customerName,
        'po': value.po,
        'material': value.material,
        'notchFront': value.notchFront,
        'notchBack': value.notchBack,
        'notes': value.notes,
        'finished': value.finished,
        'drilling': value.drilling,
        'garbagePullouts': value.garbagePullouts,
        'reduceSides': value.reduceSides,
        'wallCutlistSortBy': _1.WallCutlistSortByToJSON(value.wallCutlistSortBy),
        'dovetailList': (value.dovetailList.map(_1.DovetailToJSON)),
    };
}
exports.DovetailMaterialsBodyToJSON = DovetailMaterialsBodyToJSON;
