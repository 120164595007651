"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Klaas Custom Woodworking LTD.
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 * no description
 */
class AdminApi extends runtime.BaseAPI {
    /**
     * Used to login an admin account. Returns a token if successful
     */
    async adminLoginRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/admin/admin_login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.AdminLoginBodyToJSON(requestParameters.adminLoginBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.TokenFromJSON(jsonValue));
    }
    /**
     * Used to login an admin account. Returns a token if successful
     */
    async adminLogin(requestParameters) {
        const response = await this.adminLoginRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used by admins to change their passwords. Also used by GOD admins to change other admin passwords.
     */
    async changeAdminPasswordRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/change_admin_password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.ChangeAdminPasswordBodyToJSON(requestParameters.changeAdminPasswordBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by admins to change their passwords. Also used by GOD admins to change other admin passwords.
     */
    async changeAdminPassword(requestParameters) {
        await this.changeAdminPasswordRaw(requestParameters);
    }
    /**
     * Used by GOD admins to change user\'s passwords.
     */
    async changeUserPasswordRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/change_user_password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.ChangeUserPasswordBodyToJSON(requestParameters.changeUserPasswordBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by GOD admins to change user\'s passwords.
     */
    async changeUserPassword(requestParameters) {
        await this.changeUserPasswordRaw(requestParameters);
    }
    /**
     * Used by admins to create new admin accounts
     */
    async createRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.CreateAdminBodyToJSON(requestParameters.createAdminBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by admins to create new admin accounts
     */
    async create(requestParameters) {
        await this.createRaw(requestParameters);
    }
    /**
     * Used by GOD admins to disable/re-enable an admin\'s ability to log in
     */
    async disableOrReEnableAdminRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/disable_or_re_enable_admin`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IDBodyToJSON(requestParameters.iDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by GOD admins to disable/re-enable an admin\'s ability to log in
     */
    async disableOrReEnableAdmin(requestParameters) {
        await this.disableOrReEnableAdminRaw(requestParameters);
    }
    /**
     * Used by GOD admins to disable/re-enable an user\'s ability to log in
     */
    async disableOrReEnableUserRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/disable_or_re_enable_user`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.IDBodyToJSON(requestParameters.iDBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by GOD admins to disable/re-enable an user\'s ability to log in
     */
    async disableOrReEnableUser(requestParameters) {
        await this.disableOrReEnableUserRaw(requestParameters);
    }
    /**
     */
    async getAdminListRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/get_admin_list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.AdminFromJSON));
    }
    /**
     */
    async getAdminList() {
        const response = await this.getAdminListRaw();
        return await response.value();
    }
    /**
     */
    async getUserListRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/get_user_list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.UserFromJSON));
    }
    /**
     */
    async getUserList() {
        const response = await this.getUserListRaw();
        return await response.value();
    }
    /**
     */
    async profileGetRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Admin", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admin/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.AdminFromJSON(jsonValue));
    }
    /**
     */
    async profileGet() {
        const response = await this.profileGetRaw();
        return await response.value();
    }
}
exports.AdminApi = AdminApi;
