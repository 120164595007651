import React, {ChangeEventHandler, MouseEventHandler, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Button, Col, Container, Input, Label, Row, FormGroup} from "reactstrap";
import InchInput from "../components/InchInput";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {BuildApi, WallCutlistSortBy, Dovetail as IDovetail} from "client";
import getConfig from "../utils/getConfig";
import FileSaver from "file-saver";
import { cloneDeep, isNil } from "lodash";
import getDefaultDovetailValues from "../utils/getDefaultDovetailValues";
import {measurementSubtract} from "../utils/measurementUtils";

interface IProps {
	dispatch: any;
	token: string;
}

const spacerHeight: number = 30;
const rowHeight: number = 50;
const rowMargin: number = 20;
export type DovetailExtended = {heightInch?: string, heightFraction?: string, widthInch?: string, widthFraction?: string, depthInch?: string, depthFraction?: string} & Partial<IDovetail>
type Transform = (d: DovetailExtended) => {quantity?: string, width?: string, height?: string};

const Dovetail: React.FC<IProps> = (props) => {

	const [customerName, setCustomerName] = useState("");
	const [po, setPo] = useState("");
	const [material, setMaterial] = useState("");
	const [notchFront, setNotchFront] = useState("");
	const [notchBack, setNotchBack] = useState("");
	const [notes, setNotes] = useState("");
	const [finished, setFinished] = useState(false);
	const [drilling, setDrilling] = useState(false);
	const [garbagePullouts, setGarbagePullouts] = useState(false);
	const [reduceSides, setReduceSides] = useState(false);
	const [wallCutlistSortBy, setWallCutlistSortBy] = useState<WallCutlistSortBy>(WallCutlistSortBy.HARDWOOD);
	const [dovetailList, setDovetailList] = useState<Array<DovetailExtended>>(getDefaultDovetailValues());

	const dovetailListProper: IDovetail[] = dovetailList.map(d => ({
		height: (!isNil(d.heightInch) && !isNil(d.heightFraction)) ? d.heightInch + " " + d.heightFraction : undefined,
		width: (!isNil(d.widthInch) && !isNil(d.widthFraction)) ? d.widthInch + " " + d.widthFraction : undefined,
		depth: (!isNil(d.depthInch) && !isNil(d.depthFraction)) ? d.depthInch + " " + d.depthFraction : undefined,
		quantity: !isNil(d.quantity) ? d.quantity : undefined,
		notes: !isNil(d.notes) ? d.notes : undefined,
	}))

	async function generatePDF() {
		props.dispatch(incrementLoading());
		try {
			const res = await new BuildApi(getConfig(props.token)).getDovetailMaterialsPDF({
				dovetailMaterialsBody: {
					customerName,
					po,
					material,
					notchFront,
					notchBack,
					notes,
					finished,
					drilling,
					garbagePullouts,
					reduceSides,
					wallCutlistSortBy,
					dovetailList: dovetailListProper,

				}
			});
			FileSaver.saveAs(res, "Dovetail.pdf");
		} catch (err) {
			props.dispatch(addError(err));
		}
		props.dispatch(decrementLoading());
	}

	function onChangeFunc(func: (v: any) => void): ChangeEventHandler<HTMLInputElement> {
		return e => {
			func(e.target.value)
		}
	}

	function onClickCheckbox(func: (v: boolean) => void, v: boolean): ChangeEventHandler<HTMLInputElement> {
		return e => {
			func(!v);
		}
	}

	function createFinishedSideRow(data: DovetailExtended, i: number) {

		const onQtyChange: ChangeEventHandler<HTMLInputElement> = (e) => {
			const copy = cloneDeep(dovetailList);
			copy[i].quantity = e.target.value;
			setDovetailList(copy);
		}

		function onWidthChange(inch: string, fraction: string) {
			const copy = cloneDeep(dovetailList);
			copy[i].widthInch = inch;
			copy[i].widthFraction = fraction;
			setDovetailList(copy);
		}

		function onHeightChange(inch: string, fraction: string) {
			const copy = cloneDeep(dovetailList);
			copy[i].heightInch = inch;
			copy[i].heightFraction = fraction;
			setDovetailList(copy);
		}

		function onDepthChange(inch: string, fraction: string) {
			const copy = cloneDeep(dovetailList);
			copy[i].depthInch = inch;
			copy[i].depthFraction = fraction;
			setDovetailList(copy);
		}

		return (
			<div className="w-100 justify-content-around bg-dark d-flex align-items-center px-2" style={{marginBottom: rowMargin, height: rowHeight}}>

				{/*quantity*/}
				<div className="bg-dark px-1 d-flex justify-content-center align-items-center" style={{height: rowHeight}}>
					<label className="text-white mr-2 mb-0">Qty:</label>
					<Input key={`finished-side-quantity-${i}`} style={{minWidth: "65px"}} type="number" value={data.quantity} onChange={onQtyChange}/>
				</div>

				{/*width*/}
				<div className="bg-dark px-1 d-flex justify-content-center align-items-center" style={{height: rowHeight}}>
					<label className="text-white mr-2 mb-0">W:</label>
					<InchInput inch={data.widthInch} fraction={data.widthFraction} onChange={onWidthChange}/>
				</div>

				{/*depth*/}
				<div className="bg-dark px-1 d-flex justify-content-center align-items-center" style={{height: rowHeight}}>
					<label className="text-white mr-2 mb-0">D:</label>
					<InchInput inch={data.depthInch} fraction={data.depthFraction} onChange={onDepthChange}/>
				</div>

				{/*height*/}
				<div className="bg-dark px-1 d-flex justify-content-center align-items-center" style={{height: rowHeight}}>
					<label className="text-white mr-2 mb-0">H:</label>
					<InchInput inch={data.heightInch} fraction={data.heightFraction} onChange={onHeightChange}/>
				</div>

			</div>
		)
	}

	function createDetails(transform: (d: IDovetail) => {quantity?: string, width?: string, height?: string}, header: string) {

		function createCol(label: string, key: "quantity" | "width" | "height" | "x") {

			function createRows(dovetail: IDovetail, i: number) {
				const transformedValue = transform(dovetail)[key];
				const value = key === "x" ? "x" : (transformedValue !== undefined && transformedValue !== NaN.toString()) ? transformedValue : "-";

				return (
					<div
						className="w-100 bg-dark d-flex justify-content-center align-items-center px-2"
						key={`${header}-${label}-${key}-${i}`}
						style={{
							height: rowHeight,
							borderRightWidth: key === "height" ? "1px" : undefined,
							borderRight: key === "height" ? "solid" : undefined,
							borderColor: "white",
							marginBottom: rowMargin,
						}}
					>
						<h6 className="text-white text-center w-100 m-0 text-nowrap">{value}</h6>
					</div>
				)
			}

			return (
				<div className="w-100">
					<div style={{height: spacerHeight}} className="w-100 d-flex justify-content-center align-items-center">
						<h6 className="text-center w-100 m-0 text-nowrap" >{label}</h6>
					</div>

					{dovetailListProper.map(createRows)}
				</div>
			)
		}

		return (
			<div className="w-100" style={{minWidth: "280px"}}>
				<div className="px-2 py-2 bg-dark d-flex justify-content-center">
					<h5 className="text-white text-center w-100 m-0 text-nowrap">{header}</h5>
				</div>
				<div className="d-flex w-100 flex-row justify-content-between mr-1">
					{createCol("Qty", "quantity")}
					{createCol("x", "x")}
					{createCol("W", "width")}
					{createCol("x", "x")}
					{createCol("H", "height")}
				</div>
				<div className="py-1 bg-dark"/>
			</div>
		)
	}

	function createNote(data: IDovetail, i) {
		const onNoteChange: ChangeEventHandler<HTMLInputElement> = e => {
			const copy = cloneDeep(dovetailList);
			copy[i].notes = e.target.value;
			setDovetailList(copy);
		}
		return (
			<div className="bg-dark px-2 d-flex justify-content-center align-items-center" style={{height: rowHeight, marginBottom: rowMargin}}>
				<Input style={{minWidth: "300px"}} value={data.notes} onChange={onNoteChange}/>
			</div>
		)
	}

	function createButtons(data: IDovetail, i: number) {

		function add() {
			const copy = cloneDeep(dovetailList);
			copy.splice(i + 1, 0, {});
			setDovetailList(copy);
		}

		function remove() {
			if (dovetailListProper.length > 1) {
				const copy = cloneDeep(dovetailList);
				copy.splice(i, 1);
				setDovetailList(copy);
			}
		}

		return (
			<div style={{height: rowHeight, marginBottom: rowMargin}} key={`button-${i}`} className="px-2 d-flex flex-column justify-content-around">
				<div
					className="bg-dark text-white d-flex justify-content-center align-items-center"
					style={{height: "22px", width: "22px", borderRadius: "11px", cursor: "pointer"}}
					onClick={add}
				>
					+
				</div>
				<div
					className="bg-dark text-white d-flex justify-content-center align-items-center"
					style={{height: "22px", width: "22px", borderRadius: "11px", cursor: "pointer"}}
					onClick={remove}
				>
					-
				</div>
			</div>
		)
	}

	const endsTransform: Transform = d => ({height: d.width, width: d.height, quantity: (Number(d.quantity) * 2).toString()});
	const sidesTransform: Transform = d => ({height: measurementSubtract(d.depth, "1/2"), width: d.height, quantity: (Number(d.quantity) * 2).toString()});
	const panelsTransform: Transform = d => ({height: measurementSubtract(d.depth, "11/16"), width: measurementSubtract(d.width, "3/4"), quantity: d.quantity});

	return (
			<div className="mb-5 px-5">
				<h1>
					Dovetail
				</h1>

				<hr/>

				<Row>

					<Col md={7}>
						<Row className="mb-3">
							<Col md={9}>
								<Label>Customer</Label>
								<Input value={customerName} onChange={onChangeFunc(setCustomerName)}/>
							</Col>
							<Col md={3}>
								<Label>PO</Label>
								<Input value={po} onChange={onChangeFunc(setPo)}/>
							</Col>
						</Row>

						<Row>
							<Col md={4}>
								<Label>Material</Label>
								<Input value={material} onChange={onChangeFunc(setMaterial)}/>
							</Col>

							<Col md={4}>
								<Label>Notch Front</Label>
								<Input value={notchFront} onChange={onChangeFunc(setNotchFront)}/>
							</Col>

							<Col md={4}>
								<Label>Notch Back</Label>
								<Input value={notchBack} onChange={onChangeFunc(setNotchBack)}/>
							</Col>
						</Row>
					</Col>

					<Col md={5} className="d-flex flex-column">
						<Label>Notes</Label>
						<Input className="flex-grow-1" type="textarea" value={notes} onChange={onChangeFunc(setNotes)}/>
					</Col>

				</Row>

				<hr/>

				<div style={{overflowX: "scroll"}} className="d-flex flex-row w-100">

					{/*Buttons*/}
					<div>
						<div className="px-2 py-2 bg-dark d-flex justify-content-center">
							<h5 className="text-white text-center w-100 m-0 text-nowrap">&nbsp;&nbsp;</h5>
						</div>

						<div style={{height: spacerHeight}}/>

						{dovetailListProper.map(createButtons)}

						<div className="py-1 bg-dark"/>
					</div>

					{/*Finished Sides*/}
					<div className="w-100">
						<div className="px-2 py-2 bg-dark d-flex justify-content-center">
							<h5 className="text-white text-center w-100 m-0 text-nowrap">Finished Sides</h5>
						</div>

						<div style={{height: spacerHeight}}/>

						{dovetailList.map(createFinishedSideRow)}

						<div className="py-1 bg-dark"/>
					</div>

					{/*Ends*/}
					{createDetails(endsTransform, "Ends")}

					{/*Sides*/}
					{createDetails(sidesTransform, "Sides")}

					{/*Panels*/}
					{createDetails(panelsTransform, "Panels")}


					{/*Notes*/}
					<div className="w-100">
						<div className="px-2 py-2 bg-dark d-flex justify-content-center">
							<h5 className="text-white text-center w-100 m-0 text-nowrap">Notes</h5>
						</div>

						<div style={{height: spacerHeight}}/>


						{dovetailListProper.map(createNote)}

						<div className="py-1 bg-dark"/>
					</div>

				</div>

				<hr/>

				<Row>
					<Col md={4} sm={12}>
						<Label>Sort As</Label>
						<Input type="select" value={wallCutlistSortBy} onChange={onChangeFunc(setWallCutlistSortBy)}>
							<option value={WallCutlistSortBy.HARDWOOD}>Hardwood</option>
							<option value={WallCutlistSortBy.BALTICBIRCH}>Baltic Birch</option>
						</Input>
					</Col>
					<Col md={8} sm={12} className="d-flex flex-column">
						<Label>Other Options</Label>
						<div className="d-flex flex-grow-1 flex-row align-items-center h-100">
							<p className="p-0 m-0 d-flex flex-row">
								<div className="mr-2">
									<Input id="finished" name="finished" type="checkbox" checked={finished} onChange={onClickCheckbox(setFinished, finished)}/>
									<Label htmlFor="finished">Finished</Label>
								</div>
								<div className="mr-2">
									<Input id="garbage" name="garbage" type="checkbox" checked={garbagePullouts} onChange={onClickCheckbox(setGarbagePullouts, garbagePullouts)}/>
									<Label htmlFor="garbage">Garbage Pullouts</Label>
								</div>
								<div className="mr-2">
									<Input id="reduce sides" name="reduce sides" type="checkbox" checked={reduceSides} onChange={onClickCheckbox(setReduceSides, reduceSides)}/>
									<Label htmlFor="reduce sides">Reduce Sides</Label>
								</div>
								<div>
									<Input id="drilling" name="drilling" type="checkbox" checked={drilling} onChange={onClickCheckbox(setDrilling, drilling)}/>
									<Label htmlFor="drilling">Drilling</Label>
								</div>
							</p>
						</div>
					</Col>
				</Row>

				<hr/>

				<div className="d-flex justify-content-end mt-4">
					<Button color="primary" onClick={generatePDF}>
						Generate PDF
					</Button>
				</div>
			</div>
	)
}

export default connect((store: IStore) => {
	return {
		token: store.metaStore.token,
	}
})(Dovetail);