import React from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import ErrorManager from "./components/ErrorManager";
import LoadingManager from "./components/LoadingManager";
import TokenManager from "./components/TokenManager";
import SideBarManager from "./components/sideBar/SideBarManager";
import Login from "./pages/Login";
import NotFoundPage from "./pages/NotFoundPage";
import Dashboard from "./pages/Dashboard";
import ManageAdmins from "./pages/ManageAdmins";
import ManageUsers from "./pages/ManageUsers";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import SpiceRack from "./pages/SpiceRack";
import Dovetail from "./pages/Dovetail";

const App: React.FC = () => {

	return (
		<BrowserRouter>

			<ErrorManager/>
			<LoadingManager/>
			<TokenManager/>

			<SideBarManager>
				<main className="mh-100">
					<Switch>
						<Route exact path="/" component={Login}/>
						<AuthenticatedRoute exact path="/dashboard" component={Dashboard}/>
						<AuthenticatedRoute exact path="/manage-admins" component={ManageAdmins}/>
						<AuthenticatedRoute exact path="/manage-users" component={ManageUsers}/>
						<AuthenticatedRoute exact path="/spice-rack" component={SpiceRack}/>
						<AuthenticatedRoute exact path="/dovetail" component={Dovetail}/>
						<Route exact path="/404" component={NotFoundPage}/>
						<Redirect to="/404"/>
					</Switch>
				</main>
			</SideBarManager>
		</BrowserRouter>
	);
};

export default App;
