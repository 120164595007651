"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Klaas Custom Woodworking LTD.
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserToJSON = exports.UserFromJSONTyped = exports.UserFromJSON = void 0;
const runtime_1 = require("../runtime");
function UserFromJSON(json) {
    return UserFromJSONTyped(json, false);
}
exports.UserFromJSON = UserFromJSON;
function UserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'active': json['active'],
        'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': !runtime_1.exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'passwordHash': !runtime_1.exists(json, 'passwordHash') ? undefined : json['passwordHash'],
        'firstName': !runtime_1.exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !runtime_1.exists(json, 'lastName') ? undefined : json['lastName'],
    };
}
exports.UserFromJSONTyped = UserFromJSONTyped;
function UserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'active': value.active,
        'email': value.email,
        'phoneNumber': value.phoneNumber,
        'passwordHash': value.passwordHash,
        'firstName': value.firstName,
        'lastName': value.lastName,
    };
}
exports.UserToJSON = UserToJSON;
