import {Input} from "reactstrap";
import React, {ChangeEventHandler, KeyboardEventHandler, useRef, useState} from "react";

interface IProps {
	inch?: string;
	fraction?: string;
	onChange?: (inch: string, fraction: string) => void;
}

const InchInput: React.FC<IProps> = (props) => {

	const [inchFocus, setInchFocus] = useState(false);
	const [fractionFocus, setFractionFocus] = useState(false);
	const inchRef = useRef<HTMLInputElement>();
	const fractionRef = useRef<HTMLInputElement>();

	const inchOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		props.onChange(e.target.value.trim(), props.fraction)
	}

	const fractionOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		props.onChange(props.inch, e.target.value.trim())
	}

	const inchFocusFunction = (focused: boolean) => () => {
		setInchFocus(focused);
	}

	const fractionFocusFunction = (focused: boolean) => () => {
		setFractionFocus(focused);
	}

	const onInchKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
		if ((e.key === "ArrowRight" || e.key === "Tab") && inchRef.current.selectionEnd === props.inch.length) {
			fractionRef.current.focus();
			setTimeout(() => {
				fractionRef.current.selectionStart = 0;
				fractionRef.current.selectionEnd = 0;
			}, 0)

		}
	}

	const onFractionKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
		if (e.key === "ArrowLeft" && fractionRef.current.selectionEnd === 0) {
			inchRef.current.focus();
			setTimeout(() => {
				inchRef.current.selectionStart = props.inch.length;
				inchRef.current.selectionEnd = props.inch.length;
			}, 0)
		}
	}

	const isValidFraction =	new RegExp(/[1-9][0-9]*\/[1-9][0-9]*/g).test(props.fraction) || props.fraction === "0" || props.fraction === "";
	const validInch = !isNaN(Number(props.inch)) || props.inch === "0" || props.inch === "";
	const focused = (inchFocus || fractionFocus) ? "focus" : ""

	return (
		<div
			className={`input form-control d-flex flex-row align-items-center`}
			style={focused ? {
				color: "#495057",
				backgroundColor: "#fff",
				borderColor: "#80bdff",
				outline: 0,
				boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, 0.25)",
				minWidth: "90px"
			} : {minWidth: "90px"}}
		>
			<input
				className="form-control border-0 m-0 p-0 bg-transparent"
				style={{
					boxShadow: "none",
					width: props.inch.length > 0 ? props.inch.length + "ch" : "1.5ch",
					textDecoration: "underline",
					color: validInch ? undefined : "red"
				}}
				onChange={inchOnChange}
				value={props.inch.length < 1 ? "  " : props.inch}
				onFocus={inchFocusFunction(true)}
				onBlur={inchFocusFunction(false)}
				onKeyDown={onInchKeyDown}
				ref={inchRef}
			/>
			<p>&nbsp;</p>
			<input
				className="form-control border-0 m-0 p-0 bg-transparent"
				style={{
					boxShadow: "none",
					width: props.fraction.length > 0 ? props.fraction.length + "ch" : "1.5ch",
					textDecoration: "underline",
					color: isValidFraction ? undefined : "red"
				}}
				onChange={fractionOnChange}
				value={props.fraction.length < 1 ? "  " : props.fraction}
				onFocus={fractionFocusFunction(true)}
				onBlur={fractionFocusFunction(false)}
				onKeyDown={onFractionKeyDown}
				ref={fractionRef}
			/>
		</div>
	)
}

InchInput.defaultProps = {
	inch: "",
	fraction: "",
	onChange: () => {},
}

export default InchInput;