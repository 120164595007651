"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Klaas Custom Woodworking LTD.
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomSpiceRackItemToJSON = exports.CustomSpiceRackItemFromJSONTyped = exports.CustomSpiceRackItemFromJSON = void 0;
function CustomSpiceRackItemFromJSON(json) {
    return CustomSpiceRackItemFromJSONTyped(json, false);
}
exports.CustomSpiceRackItemFromJSON = CustomSpiceRackItemFromJSON;
function CustomSpiceRackItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'quantity': json['quantity'],
        'width': json['width'],
        'height': json['height'],
    };
}
exports.CustomSpiceRackItemFromJSONTyped = CustomSpiceRackItemFromJSONTyped;
function CustomSpiceRackItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'quantity': value.quantity,
        'width': value.width,
        'height': value.height,
    };
}
exports.CustomSpiceRackItemToJSON = CustomSpiceRackItemToJSON;
