"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Klaas Custom Woodworking LTD.
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResetForgotPasswordBodyToJSON = exports.ResetForgotPasswordBodyFromJSONTyped = exports.ResetForgotPasswordBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
function ResetForgotPasswordBodyFromJSON(json) {
    return ResetForgotPasswordBodyFromJSONTyped(json, false);
}
exports.ResetForgotPasswordBodyFromJSON = ResetForgotPasswordBodyFromJSON;
function ResetForgotPasswordBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'forgotPasswordID': !runtime_1.exists(json, 'forgotPasswordID') ? undefined : json['forgotPasswordID'],
        'verificationCode': !runtime_1.exists(json, 'verificationCode') ? undefined : json['verificationCode'],
        'newPassword': json['newPassword'],
        'confirmPassword': json['confirmPassword'],
    };
}
exports.ResetForgotPasswordBodyFromJSONTyped = ResetForgotPasswordBodyFromJSONTyped;
function ResetForgotPasswordBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'forgotPasswordID': value.forgotPasswordID,
        'verificationCode': value.verificationCode,
        'newPassword': value.newPassword,
        'confirmPassword': value.confirmPassword,
    };
}
exports.ResetForgotPasswordBodyToJSON = ResetForgotPasswordBodyToJSON;
