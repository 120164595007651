"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Klaas Custom Woodworking LTD.
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpiceRackQuantitiesToJSON = exports.SpiceRackQuantitiesFromJSONTyped = exports.SpiceRackQuantitiesFromJSON = void 0;
function SpiceRackQuantitiesFromJSON(json) {
    return SpiceRackQuantitiesFromJSONTyped(json, false);
}
exports.SpiceRackQuantitiesFromJSON = SpiceRackQuantitiesFromJSON;
function SpiceRackQuantitiesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'fourHalf': json['fourHalf'],
        'six': json['six'],
        'eight': json['eight'],
        'ten': json['ten'],
        'twelve': json['twelve'],
    };
}
exports.SpiceRackQuantitiesFromJSONTyped = SpiceRackQuantitiesFromJSONTyped;
function SpiceRackQuantitiesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'fourHalf': value.fourHalf,
        'six': value.six,
        'eight': value.eight,
        'ten': value.ten,
        'twelve': value.twelve,
    };
}
exports.SpiceRackQuantitiesToJSON = SpiceRackQuantitiesToJSON;
