"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Klaas Custom Woodworking LTD.
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateAdminBodyToJSON = exports.CreateAdminBodyFromJSONTyped = exports.CreateAdminBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CreateAdminBodyFromJSON(json) {
    return CreateAdminBodyFromJSONTyped(json, false);
}
exports.CreateAdminBodyFromJSON = CreateAdminBodyFromJSON;
function CreateAdminBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'adminRole': _1.AdminRoleFromJSON(json['adminRole']),
        'username': json['username'],
        'password': json['password'],
        'confirmPassword': json['confirmPassword'],
        'firstName': !runtime_1.exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !runtime_1.exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
    };
}
exports.CreateAdminBodyFromJSONTyped = CreateAdminBodyFromJSONTyped;
function CreateAdminBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'adminRole': _1.AdminRoleToJSON(value.adminRole),
        'username': value.username,
        'password': value.password,
        'confirmPassword': value.confirmPassword,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
    };
}
exports.CreateAdminBodyToJSON = CreateAdminBodyToJSON;
