import React, {ChangeEventHandler, useState} from "react";
import {Button, Container, Input, Label} from "reactstrap";
import {connect} from "react-redux";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {BuildApi, SpiceRackMaterial, WallCutlistSortBy} from "client/dist";
import getConfig from "../utils/getConfig";
import FileSaver from "file-saver";
import {IStore} from "../redux/defaultStore";
import InchInput from "../components/InchInput";
import {omitBy, isNil} from "lodash";
import {CustomSpiceRackItem} from "client";

interface IProps {
    dispatch: any;
    token: string;
}

const SpiceRack: React.FC<IProps> = (props) => {

    const [fourHalf, setFourHalf] = useState(0);
    const [six, setSix] = useState(0);
    const [eight, setEight] = useState(0);
    const [ten, setTen] = useState(0);
    const [twelve, setTwelve] = useState(0);
    const [customQuantity, setCustomQuantity] = useState<number>(undefined);
    const [customWidthInch, setCustomWidthInch] = useState<string>(undefined);
    const [customWidthFraction, setCustomWidthFraction] = useState<string>(undefined);
    const [customHeightInch, setCustomHeightInch] = useState<string>(undefined);
    const [customHeightFraction, setCustomHeightFraction] = useState<string>(undefined);
    const [material, setMaterial] = useState<SpiceRackMaterial>(SpiceRackMaterial.MAPLE);

    function createOnChange(onChangeFunc: (v: number) => void): ChangeEventHandler<HTMLInputElement> {
        return e => {
            const num = Number(e.target.value);
            if (!isNaN(num)) {
                onChangeFunc(num);
            }
        }
    }

    function createMeasurementOnChange(changeInch: (v: string) => void, changeFraction: (v: string) => void): (inch: string, fraction: string) => void {
        return (inch, fraction) => {
            changeInch(typeof inch === "string" && inch.length > 0 ? inch : undefined);
            changeFraction(typeof fraction === "string" && fraction.length > 0 ? fraction : undefined);
        }
    }

    function onSelectChange(onChangeFunc: (v: any) => void): ChangeEventHandler<HTMLInputElement> {
        return e => {
            onChangeFunc(e.target.value);
        }
    }

    async function generatePDF() {
        props.dispatch(incrementLoading());

        let customItem = omitBy({
            quantity: customQuantity,
            width: (customWidthInch && customWidthFraction) ?
                customWidthInch + " " + customWidthFraction :
                customWidthInch || undefined,
            height: (customHeightInch && customHeightFraction) ?
                customHeightInch + " " + customHeightFraction :
                customHeightInch || undefined,
        }, isNil)

        if ((customItem as CustomSpiceRackItem)?.quantity === 0 || Object.keys(customItem).length < 1) {
            customItem = undefined;
        }

        try {
            const res = await new BuildApi(getConfig(props.token)).getSpicePartListPDF({
                spicePartListBody: {
                    material,
                    customItem: customItem as CustomSpiceRackItem,
                    quantities: {
                        fourHalf,
                        six,
                        eight,
                        ten,
                        twelve
                    }
                }
            });
            FileSaver.saveAs(res, "SpiceRack.pdf");
        } catch (err) {
            props.dispatch(addError(err));
        }
        props.dispatch(decrementLoading());
    }

    return (
        <div className="px-3">
            <Container className="my-5">
                <div className="mb-5">
                    <h1>
                        Spice Rack
                    </h1>

                    <hr/>

                    <Label>Material</Label>
                    <Input type="select" value={material} onChange={onSelectChange(setMaterial)} style={{maxWidth: "300px"}}>
                        <option value={SpiceRackMaterial.MAPLE}>Maple</option>
                        <option value={SpiceRackMaterial.BALTIC}>Baltic</option>
                    </Input>

                    <div className="mt-4 d-flex flex-row w-100" style={{overflow: "scroll"}}>

                        <div>
                            <div className="px-2 py-2 bg-dark d-flex justify-content-center">
                                <h5 className="text-white text-center w-100 m-0 text-nowrap">Unit Width: 4 1/2"</h5>
                            </div>
                            <div className="d-flex align-items-center px-2 my-2">
                                <label className="m-0">Qty: </label>
                                <Input
                                    className="ml-2"
                                    type="number"
                                    value={fourHalf}
                                    onChange={createOnChange(setFourHalf)}
                                />
                            </div>
                            <div className="py-1 bg-dark"/>
                        </div>


                        <div>
                            <div className="px-2 py-2 bg-dark d-flex justify-content-center">
                                <h5 className="text-white text-center w-100 m-0 text-nowrap">Unit Width: 6"</h5>
                            </div>
                            <div className="d-flex align-items-center px-2 my-2">
                                <label className="m-0">Qty: </label>
                                <Input
                                    className="ml-2"
                                    type="number"
                                    value={six}
                                    onChange={createOnChange(setSix)}
                                />
                            </div>
                            <div className="py-1 bg-dark"/>
                        </div>

                        <div>
                            <div className="px-2 py-2 bg-dark d-flex justify-content-center">
                                <h5 className="text-white text-center w-100 m-0 text-nowrap">Unit Width: 8"</h5>
                            </div>
                            <div className="d-flex align-items-center px-2 my-2">
                                <label className="m-0">Qty: </label>
                                <Input
                                    className="ml-2"
                                    type="number"
                                    value={eight}
                                    onChange={createOnChange(setEight)}
                                />
                            </div>
                            <div className="py-1 bg-dark"/>
                        </div>

                        <div>
                            <div className="px-2 py-2 bg-dark d-flex justify-content-center">
                                <h5 className="text-white text-center w-100 m-0 text-nowrap">Unit Width: 10"</h5>
                            </div>
                            <div className="d-flex align-items-center px-2 my-2">
                                <label className="m-0">Qty: </label>
                                <Input
                                    className="ml-2"
                                    type="number"
                                    value={ten}
                                    onChange={createOnChange(setTen)}
                                />
                            </div>
                            <div className="py-1 bg-dark"/>
                        </div>

                        <div>
                            <div className="px-2 py-2 bg-dark d-flex justify-content-center">
                                <h5 className="text-white text-center w-100 m-0 text-nowrap">Unit Width: 12"</h5>
                            </div>
                            <div className="d-flex align-items-center px-2 my-2">
                                <label className="m-0">Qty: </label>
                                <Input
                                    className="ml-2"
                                    type="number"
                                    value={twelve}
                                    onChange={createOnChange(setTwelve)}
                                />
                            </div>
                            <div className="py-1 bg-dark"/>
                        </div>

                    </div>

                    <h4 className="mt-4">Custom Item</h4>
                    <p>For custom spice racks, insert a quantity, height and width to generate different sizes.</p>
                    <div className="d-flex flex-row">
                        <div className="d-flex align-items-center px-2 my-2">
                            <label className="mb-0">Qty: </label>
                            <Input
                                className="ml-2"
                                type="number"
                                value={customQuantity}
                                onChange={createOnChange(setCustomQuantity)}
                            />
                        </div>

                        <div className="d-flex align-items-center px-2 my-2">
                            <label className="mr-2 mb-0">W:</label>
                            <InchInput
                                inch={customWidthInch}
                                fraction={customWidthFraction}
                                onChange={createMeasurementOnChange(setCustomWidthInch, setCustomWidthFraction)}
                            />
                        </div>

                        <div className="d-flex align-items-center px-2 my-2">
                            <label className="mr-2 mb-0">H:</label>
                            <InchInput
                                inch={customHeightInch}
                                fraction={customHeightFraction}
                                onChange={createMeasurementOnChange(setCustomHeightInch, setCustomHeightFraction)}
                            />
                        </div>

                    </div>

                    <div className="d-flex justify-content-end mt-4">
                        <Button color="primary" onClick={generatePDF}>
                            Generate PDF
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default connect((store: IStore) => {
    return {
        token: store.metaStore.token,
    }
})(SpiceRack)