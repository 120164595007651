"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Klaas Custom Woodworking LTD.
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhoneNumberVerificationTypeToJSON = exports.PhoneNumberVerificationTypeFromJSONTyped = exports.PhoneNumberVerificationTypeFromJSON = exports.PhoneNumberVerificationType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var PhoneNumberVerificationType;
(function (PhoneNumberVerificationType) {
    PhoneNumberVerificationType["User"] = "User";
})(PhoneNumberVerificationType = exports.PhoneNumberVerificationType || (exports.PhoneNumberVerificationType = {}));
function PhoneNumberVerificationTypeFromJSON(json) {
    return PhoneNumberVerificationTypeFromJSONTyped(json, false);
}
exports.PhoneNumberVerificationTypeFromJSON = PhoneNumberVerificationTypeFromJSON;
function PhoneNumberVerificationTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.PhoneNumberVerificationTypeFromJSONTyped = PhoneNumberVerificationTypeFromJSONTyped;
function PhoneNumberVerificationTypeToJSON(value) {
    return value;
}
exports.PhoneNumberVerificationTypeToJSON = PhoneNumberVerificationTypeToJSON;
